var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-3",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"color":"primary","dense":""},on:{"click":_vm.goBackCampaign}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('span',{staticClass:"font-weight-black ml-1 ",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s('Need Approval')+" ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs',{model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_c('v-tab',[_vm._v("Campaign Manager")]),_c('v-tab',[_vm._v("Manage Salary")]),_c('v-tab',{attrs:{"text-capitalize":""}},[_vm._v(" Manage Loan ")])],1)],1)],1),(_vm.loanSubmissionsSelected.length !== 0)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"my-7"},[_c('v-btn',{staticClass:"me-3 text-capitalize",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.bulkApproveLoanSubmission()}}},[_vm._v(" Approve ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"large":"","color":"error"},on:{"click":function($event){return _vm.bulkRejectLoanSubmission()}}},[_vm._v(" Reject ")])],1)],1):_vm._e(),(_vm.tabSelected == 1)?_c('salary-approval'):_vm._e(),(_vm.tabSelected == 2)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.loanApprovalHeaders,"items":_vm.loanSubmissionsNeedApproval,"single-select":_vm.singleSelect,"show-select":""},scopedSlots:_vm._u([{key:"item.totalLoan",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatToIDRCurrency(item.totalLoan)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"my-4"},[_c('v-btn',{staticClass:"me-3 text-capitalize",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.singleApproveLoanSubmission(item.id)}}},[_vm._v(" Approve ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.singleRejectLoanSubmission(item.id)}}},[_vm._v(" Reject ")])],1)],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.goToPreview(item)}}},on),[_vm._v(" Preview Post ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Preview Post")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"600px"}},[_c('v-row',{attrs:{"justify":"space-between","align-content":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-tabs',[_c('v-tab',{staticClass:"text-capitalize",on:{"click":function($event){_vm.previewPost = 'WhatsApp'}}},[_vm._v(" WhatsApp ")]),_c('v-tab',{staticClass:"text-capitalize",on:{"click":function($event){_vm.previewPost = 'Facebook'}}},[_vm._v(" Facebook ")]),_c('v-tab',{staticClass:"text-capitalize",on:{"click":function($event){_vm.previewPost = 'Instagram'}}},[_vm._v(" Instagram ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"me-3",style:({ 'background-color': hover ? '#1F979E' : '#fff', color: hover ? '#fff' : '#1F979E' }),attrs:{"small":"","outlined":""}},[_vm._v(" Approve ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{style:({ 'background-color': hover ? '#FF4C51' : '#fff', color: hover ? '#fff' : '#FF4C51' }),attrs:{"small":"","outlined":""}},[_vm._v(" Reject ")])]}}],null,true)}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","small":"","text":"","outlined":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)]}}],null,false,3477965560),model:{value:(_vm.loanSubmissionsSelected),callback:function ($$v) {_vm.loanSubmissionsSelected=$$v},expression:"loanSubmissionsSelected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }